<template>
    <div id="Bio" class="flex-container">
        <div id="BioImage">
            <img src="../assetsPng/Portret_kolor-121.jpg">
        </div>
        <div id="BioText">
            <p>
                Dr n.med. Joanna Żyłkowska w 2004 roku ukończyła studia medyczne na Warszawskim Uniwersytecie
                Medycznym
                <br>
                W latach 2005 -2009 pracowała jako wykładowca w Katedrze Fizjologii Doświadczalnej i Klinicznej
                Człowieka Warszawskiego Uniwersytetu Medycznego.
                <br>
                W 2012 roku zdała egzamin specjalizacyjny z chorób wewnętrznych po przebytym szkoleniu
                specjalizacyjnym w Klinice Chorób Wewnętrznych Klatki Piersiowej Instytutu Gruźlicy i Chorób Płuc w
                Warszawie oraz Europejskim Centrum Zdrowia w Otwocku.
                <br>
                W 2013 roku obroniła z wyróżnieniem doktorat pt. „Ocena wpływu stopnia poszerzenia tętnicy
                płucnej na rokowanie w tętniczym i zakrzepowo-zatorowym nadciśnieniu płucnym”
                <br>
                Od kilkunastu lat zajmuje się medycyną estetyczną. Uczestniczyła w licznych szkoleniach
                zagranicznych z zakresu anatomii twarzy, medycyny regeneracyjnej oraz technik iniekcji kwasu
                hialuronowego i toksyny botulinowej m.in. w Londynie, Paryżu, Malmo, Dubaju, Wenecji.
                <br>
                Od kilku lat jest trenerem i wykładowcą w ramach Allergan Medical Institute regularnie prowadząc
                szkolenia dla lekarzy w całej Polsce.
                <br>
                Członkini Stowarzyszenia Lekarzy Dermatologów Estetycznych.
                <br>
                Zajmuje się również zabiegami odtwórczymi u pacjentów po operacjach z powodu rozszczepu wargi i
                podniebienia oraz zabiegami miomodulacyjnymi u pacjentów z porażeniem nerwu twarzowego.
                <br>
                Prywatnie mama trójki dzieci, kochająca aktywny tryb życia i grę na fortepianie.
            </p>
        </div>
    </div>
</template>
<script>

</script>
<style lang="scss" scoped>
@import '../styles/style.scss';
    *{
    text-decoration: none;
    }
    h1{
        text-align: center;
        color: #BD924A;
    }
    .flex-container{
        min-height: 85vh;
        display: flex;
        justify-content: center;
        justify-content: space-around;
        align-items: center;
        #BioText{
            display: flex;
            width: 40vw;
            text-align: justify;
        }
    }
    @media only screen and (max-width: 1000px) {
        .flex-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            #BioText{
                display: flex;
                text-align: justify;
                width: 80vw;
            }
        }
    }
</style>