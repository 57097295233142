<template>
  <bannerPage/>
  <mainView/>
  <ImageModal/>
  <footerPage/>
</template>



<script>
import mainView from "./components/mainView.vue"
import bannerPage from "./components/bannerPage.vue"
import footerPage from "./components/footerPage.vue"
import ImageModal from "./components/ImageModal.vue";
export default {
  name: 'App',
  components:
  {
      mainView,
      ImageModal,
      bannerPage,
      footerPage,
  }

}
</script>

<style lang="scss">
@import './styles/style.scss';
*
{
  box-sizing: border-box;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $font-color;
  box-sizing: border-box;
}
body
{
  margin: 0;
}
</style>