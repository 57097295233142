<template>
    <div id="mainTeam">
        <div id="mainHeader">
            <h1> NASZ ZESPÓŁ</h1>
        </div>
        <div id="DrZwol">
            <div id="DrZwolHeader">
                <h2>dr n.med. Agnieszka Zwolińska</h2>
            </div>
            <div id="DrZwolBio">
                <h3><router-link to="/Dr_Zwolinska">BIO</router-link></h3>
            </div>
            <div id="DrZwolBody" class="flex-container">
                <div id="DrZwoltxt">
                    <h3>Dlaczego JA?</h3>
                    <p>
                        Dzisiaj słowo „ja” może być rozumiane na wiele sposobów.
                        Niejednokrotnie jest wypaczane, pozbawiane szacunku albo traktowane zbyt powierzchownie.
                        Dla mnie sens tego słowa to szacunek do samej/samego siebie, u podstaw którego leży miłość.
                        Nie egoizm, nie samouwielbienie, nie pycha.
                        Miłość, która pozwala czuć wolność w decyzjach, wdzięczność za „tu i teraz” oraz odwagę
                        by iść do przodu z podniesioną głową.
                        JA jako kobieta, JA jako mężczyzna, JA jako matka lub ojciec, JA jako lekarz,
                        JA jako przyjaciółka/przyjaciel, córka, syn, siostra, brat. JA taka, jaka jestem.
                        Każdy z nas zasługuje na to by jego JA zostało dostrzeżone, pokazało się, zostało
                        docenione. Jeśli będę mogła w jakikolwiek sposób temu pomóc swoją sprawną ręką i poczuć
                        się Wam bardziej JA – będę zaszczycona.
                    </p>
                </div>
                <div id="DrZwolImg">
                    <router-link to="/Dr_Zwolinska"> <img src="../assetsPng/zdjDrZwol.jpg"></router-link>
                </div>
                <div id="DrZwoltxt2">
                    <p>
                        Medycyną estetyczną zajmuje się od ponad 16 lat,
                        wykonałam w tym czasie ponad 20 tys zabiegów.
                        Zawsze sprawiają mi one wielką przyjemność.
                        Jako internista i fizjolog staram się patrzeć na pacjenta w
                        sposób całościowy, uwzględniając medyczne i psychologiczne aspekty.
                        Od 10 lat jestem trenerem Allergan Medical Institute i szkole lekarzy
                        zarówno w Polsce jak i za granicą.
                        To zamiłowanie do dzielenia się swoją wiedzą wynika z wielu lat
                        doświadczenia w roli nauczyciela akademickiego na Warszawskim
                        Uniwersytecie Medycznym. Szczególnie interesuję się miomodulacją –
                        czyli wpływem kwasu hialuronowego i toksyny botulinowej na mięśnie
                        twarzy. Metoda ta pozwala mi pomóc osobom z porażeniem nerwu
                        twarzowego i asymetriami twarzy . Moje motto? Być lepszym lekarzem
                        niż wczoraj – co staram się czynić każdego dnia.
                    </p>
                    <a id="ig1" href="https://www.instagram.com/dr_agnieszka_zwolinska/">
                        Ig: dr_agnieszka_zwolinska
                    </a>
                </div>
            </div>
        </div>
        <div id="DrZyl">
            <div id="DrZylHeader">
                <h2>dr n.med. Joanna Żyłkowska</h2>
            </div>
            <div id="DrZylBio">
                <h3><router-link to="/Dr_Zylkowska">BIO</router-link></h3>
            </div>
            <div id="DrZylBody">
                <div id="DrZylp1" class="flex-container">
                    <div id="DrZylImg">
                        <router-link to="/Dr_Zylkowska"><img src="../assetsPng/zdjDrZyl.jpg"></router-link>
                    </div>
                    <div id="DrZylTxt">
                        JA to najkrótsze ze słów uważanych za wyjątkowe,
                        a teraz oznacza również szczególne miejsce na mapie Warszawy.
                        JA to zrozumienie własnych potrzeb, JA to uważność na te potrzeby,
                        JA to mój czas, JA to energia ludzi, którzy mnie otaczają, dlatego
                        jest to też uważność skierowana do moich najbliższych.
                        JA to mój czas, moje miejsce, moja dbałość i troska o siebie tu i
                        teraz, ale również profilaktyka  z myślą o przyszłości.
                        Nasze JA powinno wzbudzać pozytywne dreszcze i ożywiać motyle w
                        brzuchu, bo jest ekscytujące, niepowtarzalne i zasługuje na szczęście.
                        JA  to uważność ukierunkowana na potrzeby moich pacjentów,
                        bo to JA pacjenta w moim gabinecie staje się najważniejsze.
                        JA łączy potrzeby, oczekiwania, indywidualność, wyjątkowość i niepowtarzalność.
                    </div>
                </div>
                <div id="DrZylp2" class="flex-container">
                    <div id="DrZylTxt2">
                        <p>
                            Moja dotychczasowa praktyka lekarska nauczyła mnie słuchać i uważnie
                            dostrzegać potrzeby pacjentów.  Kilkanaście lat praktyki w gabinecie
                            medycyny estetycznej, doświadczenie zawodowe czerpane z pracy jako
                            specjalista chorób wewnętrznych z praktyką kliniczną i naukową, z
                            doświadczaniem najczęstszych potrzeb pacjentów trafiających również
                            po pomoc do internisty  w  przychodni, pogłębianie wiedzy na temat
                            fizjologii człowieka w kilkuletniej pracy jako nauczyciel akademicki,
                            a w ostatnich latach jako trener Allergan Medical Institute dzielący
                            się swoją wiedzą z lekarzami w całej Polsce – to jest moje zawodowe JA.
                            Fascynuje mnie to, że wciąż tak wiele dowiadujemy się o naszej anatomii i
                            funkcjonowaniu naszego ciała.  Nieustannie doszkalam się, by poszerzać
                            horyzonty mojej wiedzy i móc budować nowe doświadczenia zabiegowe.
                        </p>
                    </div>
                    <div id="DrZylTxt3">
                        <p>
                            Co jest najważniejsze w moim zawodowym życiu ? Relacja  z moimi
                            pacjentami. Zaufanie, troska, odpowiedzialność, dbałość, żeby słuchać
                            i usłyszeć, ale też dbałość o bezpieczeństwo.  Moje słowo klucz to
                            równowaga. Moje motto codziennej pracy to holistyczna opieka nad
                            pacjentem, dbanie o różne aspekty zdrowotne i emocjonalne,
                            propagowanie aktywnego trybu życia. „Wielkie rzeczy nie powstają w
                            wyniku impulsu, ale w wyniku poskładania małych rzeczy w jedną całość”
                            dlatego tak ważne jest łączenie różnych metod  i spokojne planowanie
                            zabiegów.   W mojej pracy moje JA dba i wychodzi naprzeciw potrzebom
                            JA mojego pacjenta – zapraszam, wykorzystam swoje doświadczenie do
                            stworzenia Twojego planu zabiegowego.
                        </p>
                        <a id="ig2" href="https://www.instagram.com/dr_joanna_zylkowska/">
                            Ig: dr_joanna_zylkowska
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div id="AMat">
            <div id="AMatHeader">
                <h2>Anna Matracka</h2>
            </div>
            <div id="AMatBody">
                <div id="AMatp1" class="flex-container">
                    <div id="AMatImg">
                        <img src="../assetsPng/zdjAMat.jpg">
                    </div>
                    <div id="AMatTxt">
                        <p>
                            Jestem kosmetologiem, dla którego praca to nie tylko zawód, ale przede wszystkim
                            pasja i sposób na życie. Jako absolwentka Wyższej Szkoły Inżynierii i Zdrowia w
                            Warszawie, wiem że rozwój osobisty jest nieustanną częścią tego zawodu. Dla mnie
                            kluczowe jest indywidualne podejście do każdego klienta, ponieważ każda osoba jest
                            unikalna, a ich potrzeby i cele estetyczne są wyjątkowe. Budowanie trwałych i
                            zaufanych relacji z moimi klientami to dla mnie priorytet, ponieważ chcę, aby
                            każda wizyta w moim gabinecie była nie tylko okazją do poprawy wyglądu, ale
                            również do relaksu, odprężenia i wsparcia.
                        </p>
                        <p>
                            Wierzę jednak że praca kosmetologa to nie tylko dbanie o piękno zewnętrzne. W moim
                            podejściu do pielęgnacji urody zawsze uwzględniam również piękno wewnętrzne. Wierzę,
                            że prawdziwa piękno pochodzi zarówno z dbałości o skórę, jak i o zdrowie psychiczne i
                            fizyczne. Dlatego podczas konsultacji zwracam uwagę nie tylko na stan skóry, ale także
                            na styl życia, nawyki żywieniowe i poziom stresu moich klientów. W życiu kieruję się
                            zasadą, że „wszystko dzieje się po coś
                        </p>
                    </div>
                </div>
                <div id="AMatp2" class="flex-container">
                    <div id="AMatTxt2">
                        <p>
                            Staram się patrzeć na każdego klienta holistycznie, biorąc pod uwagę wszystkie
                            aspekty ich życia, które mogą wpływać na kondycję skóry i ogólny wygląd. W mojej
                            pracy używam tylko najlepszych preparatów i nowoczesnych technik, aby zapewnić
                            najlepsze rezultaty. Jednocześnie stale doskonalę swoje umiejętności i śledzę
                            najnowsze trendy i odkrycia w dziedzinie kosmetologii, aby być zawsze na bieżąco
                            i móc oferować moim klientom usługi na najwyższym poziomie. Ukończyłam liczne
                            szkolenia u najlepszych specjalistów, m.in. Klaudia Lorbiecka, Agata Zejfer,
                            Anna Salomon, Magda Komorowska
                        </p>
                    </div>
                    <div id="AMatTxt3">
                        <p>
                            Dla mnie praca kosmetologa to nie tylko zawód - to sposób na życie, który pozwala
                            mi nie tylko pomagać innym w osiągnięciu ich celów estetycznych, ale także wpływać
                            pozytywnie na ich samopoczucie i pewność siebie. Każdy dzień pracy w moim gabinecie
                            to dla mnie przywilej i okazja do tworzenia piękna, zarówno zewnętrznego, jak i
                            wewnętrznego. W Klinice JA mam szansę pracować nad wieloma aspektami naszego JA
                        </p>
                    </div>
                    <a id="ig3" href="https://www.instagram.com/aniakosmetolog_/">
                        Ig: aniakosmetolog_
                    </a>
                </div>
            </div>
        </div>
        <div id="KPab">
            <div id="KPabHeader">
                <h2>Kinga Pabisiak</h2>
            </div>
            <div id="KPabBody" class="flex-container">
                <div id="KPabImg">
                    <img src="../assetsPng/zdjKPab.jpg">
                </div>
                <div id="KPabTxt">
                    <p>
                        Jestem związana zawodowo zarówno z dr Zwolińską jak i dr Żyłkowska od ponad
                        10 lat. Praca jako asystentka lekarza dla takich specjalistów medycyny
                        estetycznej to nie tylko zajęcie - to pasja i misja. Budowanie relacji z
                        drugim człowiekiem jest dla mnie priorytetem, ponieważ chcę, aby każdy pacjent
                        poczuł się zaakceptowany, zrozumiany i otoczony wsparciem.
                    </p>
                    <p>
                        Stawanie przed wyzwaniami, nauka nowych technik i poszerzanie wiedzy to dla
                        mnie codzienność. Wierzę, że aby być najlepszą wersją siebie, muszę stale
                        pracować nad sobą i doskonalić swoje umiejętności.
                        Dlatego każdego dnia angażuję się w pracę  nie tylko dlatego, że kocham to,
                        co robię, ale również dlatego, że widzę w niej możliwość wpływania pozytywnie
                        na życie innych ludzi i na siebie samej. Praca w Klinice JA to droga do
                        stawania się coraz lepszą wersją samej siebie, zarówno zawodowo, jak i osobiście.
                    </p>

                </div>
            </div>
        </div>

        <div id="OZwol">
            <div id="OZwolHeader">
                <h2>Aleksandra Zwolińska</h2>
            </div>
            <div id="OZwolBody" class="flex-container">
                <div id="OZwoltxt">
                    <p>
                        Z wykształcenia filolog i psycholog, coach - od ponad 10 lat związania z
                        branża medyczną. W Klinice JA spełniam się jako „Ja-Manager”, który wie,
                        że kluczem do sukcesu firmy są ludzie. Moja praca to nie tylko zarządzanie
                        zadaniami i projektami, ale przede wszystkim troska o moich pracowników.
                        „Pani od wszystkiego”, która nie tylko dba o płynne funkcjonowanie firmy,
                        ale również o atmosferę pracy i zadowolenie zespołu.
                    </p>
                    <p>
                        Dla mnie najważniejsze jest, aby każdy pracownik czuł się doceniony, wsparty
                        i zmotywowany do osiągania najlepszych rezultatów. Tworzenie przyjaznej i
                        wspierającej atmosfery pracy to wg mnie priorytet, ponieważ wierzę, że tylko
                        wtedy możemy osiągać sukcesy jako zespół. Dbam o to, aby moje drzwi zawsze
                        były otwarte dla pracowników, aby mogli ze mną rozmawiać o swoich potrzebach,
                        obawach i pomysłach.
                    </p>
                </div>
                <div id="OZwolImg">
                    <img src="../assetsPng/zdjOZwol.jpg">
                </div>
                <div id="OZwoltxt2">
                    <p>
                        Jednocześnie, moja praca jako managera to nie tylko troska o pracowników,
                        ale również o naszych klientów. Rozumiem, że to oni są fundamentem naszej
                        działalności, dlatego dbam o to, aby każdy z nich otrzymywał usługi najwyższej
                        jakości i był zadowolony ze współpracy z nami. Wspieram moich pracowników w
                        podejmowaniu decyzji, które służą najlepszemu interesowi klienta i przyczyniają
                        się do budowania trwałych relacji biznesowych.
                    </p>
                    <p>
                        Praca w Klinice JA to nie tylko zarządzanie firmą, ale przede wszystkim
                        troska o ludzi - zarówno pracowników, jak i klientów. Jestem tu po to, aby
                        wspierać mój zespół w osiąganiu naszych celów zawodowych i osobistych, aby
                        razem tworzyć firmę, która nie tylko odnosi sukcesy, ale również pozytywnie
                        wpływa na życie innych.
                        Możecie się ze mną spotkać także na indywidualnych sesjach coachingowych,
                        podczas których będę Wam towarzyszyć w realizowaniu Waszych planów.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

</script>
<style lang="scss" scoped>
@import '../styles/style.scss';
    *{
    text-decoration: none;
    }
    h1{
        text-align: center;
        color: #BD924A;
    }
    #mainTeam{
        display: flex;
        flex-direction: column;
        #DrZwol{
            display: flex;
            flex-direction: column;
            #DrZwolHeader{
                display: flex;
                justify-content: center;
                text-align: center;
            }
            #DrZwolBio{
                display: flex;
                justify-content: center;
                text-align: center;
                color: #BD924A;
                text-decoration: none;
            }
            #DrZwolBody{
                display: flex;
                justify-content: space-around;
                #DrZwoltxt{
                    text-align: justify;
                    margin-right: 15px;
                }
                #DrZwoltxt2{
                    margin-left: 15px;
                    text-align: justify ;
                }
                #ig1{
                    color:#BD924A;
                }
            }
        }
        #DrZyl{
            display: flex;
            flex-direction: column;
            #DrZylHeader{
                display: flex;
                justify-content: center;
                text-align: center;
            }
            #DrZylBio{
                display: flex;
                justify-content: center;
                text-align: center;
                color: #BD924A;
                text-decoration: none;
            }
            #DrZylBody{
                display: flex;
                flex-direction: column;
                #DrZylp1{
                    display: flex;
                    justify-content: space-around;
                    #DrZylTxt{
                        text-align: justify;
                    }
                }
                #DrZylp2{
                    display: flex;
                    justify-content: space-around;
                    #DrZylTxt2{
                        text-align: justify;
                    }
                    #DrZylTxt3{
                        text-align: justify;
                    }
                }
                #ig2{
                    color:#BD924A;
                }
            }
        }
        #OZwol{
            display: flex;
            flex-direction: column;
            #OZwolHeader{
                display: flex;
                justify-content: center;
            }
            #OZwolBody{
                display: flex;
                justify-content: space-around;

                #OZwoltxt{
                    text-align: justify;
                    margin-right: 15px;
                }
                #OZwoltxt2{
                    text-align: justify;
                    margin-left: 15px;
                }
            }
        }
        #KPab{
            display: flex;
            flex-direction: column;
            #KPabHeader{
                display: flex;
                justify-content: center;
            }
            #KPabBody{
                display: flex;
                justify-content: space-around;
                #KPabTxt{
                    text-align: justify;
                }
            }
        }
        #AMat{
            display: flex;
            flex-direction: column;
            #AMatHeader{
                display: flex;
                justify-content: center;
            }
            #AMatBody{
                display: flex;
                flex-direction: column;
                #AMatp1{
                    display: flex;
                    justify-content: space-around;
                    #AMatTxt{
                        text-align: justify;
                    }
                }
                #AMatp2{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    #AMatTxt2{
                        text-align: justify;
                    }
                    #AMatTxt3{
                        text-align: justify;
                    }
                }
                #ig3{
                    color:#BD924A;
                }
            }
        }
    }
    .flex-container{
        display: flex;
        justify-content: center;
        #DrZwoltxt{
            width: 300px;
        }
        #DrZwoltxt2{
            width: 300px;
        }
        #DrZylTxt{
            width: 300px;
        }
        #DrZylTxt2{
            width: 300px;
        }
        #DrZylTxt3{
            width: 300px;
        }
        #KPabTxt{
            width: 300px;
        }
        #AMatTxt{
            width: 400px;
        }
        #AMatTxt2{
            width: 70%;
        }
        #AMatTxt3{
            width: 70%;
        }
        #OZwoltxt{
            width: 300px;
        }
        #OZwoltxt2{
            width: 300px;
        }
    }
    @media only screen and (max-width: 1000px) {
        .flex-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            #DrZwoltxt{
                width: 80vw;
            }
            #DrZwoltxt2{
                width: 80vw;
            }
            #DrZylTxt{
                width: 80vw;
            }
            #DrZylTxt2{
                width: 80vw;
            }
            #DrZylTxt3{
                width: 80vw;
            }
            #KPabTxt{
                width: 80vw;
            }
            #AMatTxt{
                width: 80vw;
            }
            #AMatTxt2{
                width: 80vw;
            }
            #AMatTxt3{
                width: 80vw;
            }
            #OZwoltxt{
                width: 80vw;
            }
            #OZwoltxt2{
                width: 80vw;
            }
        }
    }
</style>