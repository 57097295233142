<template>
    <div id="mainP">
        <div id="mainhead" ref="mainhead">
            <div id="text-pic1" class="flex-container">
                <div id="text1">
                    <div id="th1">
                        <h2>Harmonia Ciała i Ducha</h2>
                    </div>
                    <p>
                        W naszym sercu leży przekonanie, że prawdziwe piękno rodzi się z
                        harmonii między ciałem a duchem. Nasza klinika medycyny
                        estetycznej aspiruje do bycia miejscem, gdzie każdy aspekt opieki
                        nad pacjentem jest przesiąknięty głębokim zrozumieniem i
                        szacunkiem dla unikalnej indywidualności każdej osoby. Wierzymy,
                        że kluczem do osiągnięcia pełnego potencjału naszego „własnego
                        JA” jest holistyczne podejście, które łączy najnowsze osiągnięcia
                        medycyny estetycznej z tradycyjnymi metodami poprawy
                        dobrostanu.
                    </p>
                </div>
                <div id="pic1">
                    <img src="../assetsPng/zdj1.png"/>
                </div>
            </div>
            <div id="text-pic2" class="flex-container">
                <div id="pic2">
                    <img src="../assetsPng/zdj2.png">
                </div>
                <div id="text2">
                    <div id="th2">
                        <h2>Filozofia "Własnego JA"</h2>
                    </div>
                    <p>
                        Nasza filozofia opiera się na przekonaniu, że każdy z nas posiada
                        wewnętrzne piękno, które zasługuje na to, by zostać odkrytym i
                        podkreślonym. Dążymy do tego, aby nasze zabiegi nie tylko
                        poprawiały wygląd zewnętrzny, ale także wspierały wewnętrzne
                        poczucie wartości, pewności siebie i harmonii. Uważamy, że dbanie
                        o siebie nie powinno być postrzegane jako próba zmiany swojego
                        „ja” na coś lepszego, lecz jako wyraz szacunku i miłości do siebie,
                        które będzie wspierać „moje lepsze ja”:)
                    </p>
                </div>
            </div>
            <div id="text-pic3" class="flex-container">
                <div id="text3">
                    <div id="th3">
                        <h2>Holistyczne Podejście do Piękna</h2>
                    </div>
                    <p>
                        Rozumiemy, że piękno i zdrowie to nie tylko kwestie estetyczne, ale
                        także odzwierciedlenie ogólnego stanu zdrowia, równowagi
                        emocjonalnej i dobrostanu psychicznego. Dlatego nasza klinika
                        oferuje szeroki zakres usług, od zaawansowanych zabiegów
                        medycyny estetycznej, przez doradztwo w zakresie żywienia, aż po
                        konsultacje wspierające zdrowie psychiczne. Naszym celem jest
                        zapewnienie kompleksowej opieki, która przynosi korzyści na
                        wszystkich poziomach – fizycznym, emocjonalnym i duchowym.
                    </p>
                </div>
                <div id="pic3">
                    <img src="../assetsPng/zdj3.png">
                </div>
            </div>
            <div id="text-pic4" class="flex-container">
                <div id="pic4">
                    <img src="../assetsPng/zdj4.png">
                </div>
                <div id="text4">
                    <div id="th4">
                        <h2>Innowacja i Indywidualizm</h2>
                    </div>
                    <p>
                        Stawiamy na innowacyjność, ale jednocześnie głęboko wierzymy w
                        indywidualne podejście do każdego pacjenta. Każda osoba, która
                        przekracza próg naszej kliniki, jest traktowana z największą uwagą i
                        szacunkiem dla jej unikalnych potrzeb i pragnień. Słuchamy
                        naszych pacjentów, poświęcamy im czas i poszukujemy wspólnego
                        planu działania w zależności od wskazań. Nasze zabiegi są
                        dostosowane do indywidualnych celów i oczekiwań, a plan opieki
                        jest zawsze współtworzony z pacjentem, aby jak najlepiej
                        odpowiadał jego wizji siebie.
                    </p>
                </div>
            </div>
            <div id="bigtext" class="flex-container">
                <div id="text5">
                    <div id="th5">
                        <h2>Zobowiązanie do Doskonałości</h2>
                    </div>
                    <p>
                        Nasze zobowiązanie do doskonałości objawia się nie tylko w
                        wysokiej jakości świadczonych usług, ale także w ciągłym dążeniu

                        do rozwoju i edukacji. Nasz zespół to pasjonaci, którzy nieustannie
                        poszukują nowych możliwości rozwoju i doskonalenia swoich
                        umiejętności, aby móc oferować naszym pacjentom to, co w
                        medycynie estetycznej najlepsze i najbardziej innowacyjne.
                    </p>
                </div>
                <div id="text6">
                    <div id="th6">
                        <h2>Zaproszenie do Podróży</h2>
                    </div>
                    <p>
                        Zapraszamy każdego, kto pragnie pielęgnować swoje „własne JA”
                        w atmosferze szacunku, zrozumienia i profesjonalizmu do
                        dołączenia do naszej społeczności. W naszej klinice każda podróż
                        ku lepszemu samopoczuciu i piękniejszemu wyglądowi jest
                        świętowana jako krok w stronę głębszego poznania i akceptacji
                        siebie. Razem możemy odkryć pełnię Twojego potencjału i pomóc
                        Ci świecić prawdziwym, nienaruszonym pięknem, które płynie z
                        harmonii między ciałem a duchem.
                    </p>
                </div>
            </div>
        </div>
        <div id="przerywnik1">
            <img src="../assetsPng/przerywnik2.png" width="200" height="40">
        </div>
        <div id="main-entr">
            <div id="ent1" class="flex-container">
                <div id="entt1">
                    <div id="eh1">
                        <h2>Witamy w Świecie Doskonałości i Profesjonalizmu</h2>
                    </div>
                    <p>
                        Z ogromną przyjemnością zapraszamy Państwa do świata, gdzie
                        piękno spotyka się z nauką, a marzenia o doskonałym wyglądzie
                        stają się rzeczywistością. Nasza klinika medycyny estetycznej to
                        miejsce, w którym profesjonalizm, zaawansowane technologie i
                        indywidualne podejście do każdego pacjenta tworzą wyjątkową
                        przestrzeń dedykowaną Twojemu pięknu i dobrostanowi.
                    </p>
                </div>
            </div>
            <div id="eprzerywnik1">
                <img src="../assetsPng/przerywnik2.png">
            </div>
            <div id="ent2" class="flex-container">
                <div id="entp1">
                    <img src="../assetsPng/zdj5.jpg">
                </div>
                <div id="entt2">
                    <div id="eh2">
                        <h2>Dlaczego warto nas wybrać?</h2>
                    </div>
                    <p>
                        • <strong>Wybitni Specjaliści i Trenerzy:</strong> Nasz zespół tworzą
                        doświadczeni lekarze specjaliści i trenerzy, którzy są uznawani
                        za ekspertów w swojej dziedzinie. Dzięki ich wiedzy i
                        umiejętnościom zapewniamy usługi na najwyższym
                        światowym poziomie.
                        <br>
                        • <strong>Innowacyjne Zabiegi</strong>: Stale poszukujemy i wdrażamy
                        najnowsze osiągnięcia medycyny estetycznej, aby nasi
                        pacjenci mogli korzystać z najbardziej zaawansowanych i
                        skutecznych metod odmładzania i profilaktyki.
                        <br>
                        • <strong>Indywidualne Podejście</strong>: Rozumiemy, że każdy pacjent jest
                        wyjątkowy. Dlatego oferujemy spersonalizowane plany
                        zabiegów, dopasowane do indywidualnych potrzeb, oczekiwań
                        i stanu zdrowia, które powstają po wnikliwej konsultacji
                        lekarskiej i kosmetologicznej.
                        <br>
                        • <strong>Komfort i Bezpieczeństwo</strong>: Zapewniamy, że wszystkie
                        zabiegi przeprowadzane są w bezpiecznych warunkach, z
                        użyciem najwyższej jakości produktów i sprzętu. Dbamy o to,
                        by każda wizyta w naszej Klinice była dla Ciebie przyjemnym
                        doświadczeniem.
                    </p>
                </div>
            </div>
            <div id="eprzerywnik2">
                <img src="../assetsPng/przerywnik2.png">
            </div>
            <div id="ent3" class="flex-container">
                <div id="eh3">
                    <h2>Odkryj Swoje Nowe Ja</h2>
                </div>
                <div id="entt3">
                    <p>
                        Zachęcamy do zapoznania się z naszą ofertą zabiegów, które mogą
                        odmienić nie tylko Twój wygląd, ale i samopoczucie. Od terapii
                        odmładzających, przez modelowanie sylwetki, aż po
                        zaawansowane procedury medyczne – nasza Klinika oferuje
                        szeroki wachlarz usług, które pomogą Ci osiągnąć wymarzony
                        efekt.
                    </p>
                </div>
            </div>
            <div id="eprzerywnik3">
                <img src="../assetsPng/przerywnik2.png">
            </div>
            <div id="ent4" class="flex-container">
                <div id="entt4">
                    <div id="eh4">
                        <h2>Skonsultuj się z Ekspertami</h2>
                    </div>
                    <p>
                        Nie jesteś pewien, który zabieg będzie dla Ciebie najlepszy? Umów
                        się na konsultację z naszymi specjalistami. Podczas spotkania
                        omówimy Twoje oczekiwania, przeanalizujemy potrzeby Twojej
                        skóry i zaproponujemy indywidualnie dobrany plan zabiegów.
                    </p>
                </div>
                <div id="entp4" class="flex-container">
                    <img src="../assetsPng/zdjdokt2.png">
                </div>
            </div>
            <div id="eprzerywnik4">
                <br>

                <img src="../assetsPng/przerywnik2.png">
                <br>
            </div>
            <div id="ent5" class="flex-container">
                <div id="entt5">
                    <div id="eh5">
                        <h2>Dołącz do Grupy Zadowolonych Klientów</h2>
                    </div>
                    <p>
                        Naszym największym sukcesem jest satysfakcja i zaufanie naszych
                        pacjentów. Zapraszamy do zapoznania się z opiniami osób, które
                        już skorzystały z naszych usług i przekonały się o ich wysokiej
                        jakości.
                    </p>
                    <div id="eh6">
                        <h2>Zarezerwuj Wizytę</h2>
                    </div>
                    <p>
                        Nie czekaj na przemianę. Skontaktuj się z nami już dziś, aby
                        umówić swoją wizytę i rozpocząć podróż do lepszego
                        samopoczucia i piękniejszego wyglądu. Czekamy na Ciebie, aby
                        pomóc Ci odkryć Twoje nowe JA!
                    </p>
                </div>
                <div id="entp5">
                    <img src="../assetsPng/logozdj.png">
                </div>
            </div>
            <div id="eprzerywnik6">
                <img src="../assetsPng/przerywnik2.png">
            </div>
            <div id="ent6" clas="flex-container">
                <a href="https://rejestracja.medfile.pl/register/index/?uuid=3aa71cad-f0cf-1e7f-13fd-3b4f5e9eeaa1"><h2>Jesteś już zdecydowany/a? Zapisz się online na wizytę :)</h2></a>
            </div>
            <div id="eprzerywnik7">
                <img src="../assetsPng/przerywnik2.png">
            </div>
        </div>
        <div id="oferta" ref="oferta">
            <router-link to="/medycyna-estetyczna">
                <h1>MEDYCYNA ESTETYCZNA</h1>
            </router-link>
            <router-link to="/kosmetologia">
                <h1>KOSMETOLOGIA</h1>
            </router-link>
        </div>
        <div id="przerywnikend">
            <img src="../assetsPng/przerywnik2.png" width="200" height="40">
        </div>
        <br>
        <br>
    </div>
</template>

<script>

export default {
    data() {
        return {
            showRfText: false,
            showKwKText: false,
            showRetText: false,
            showMText: false,
            showSText: false,
            showBText: false,
            showKText: false,
            showWText: false,
            showOText: false,
            showMedText: false,
            showKosText: false,
            showMezoMikroText: false,
        };
    },
    methods: {
        toggleRfText() {
            this.showRfText = !this.showRfText;
        },
        toggleKwKText() {
            this.showKwKText = !this.showKwKText;
        },
        toggleRetText() {
            this.showRetText = !this.showRetText;
        },
        toggleMezoMikroText() {
            this.showMezoMikroText = !this.showMezoMikroText;
        },
        toggleKosText() {
            this.showKosText = !this.showKosText;
        },
        toggleMedText() {
            this.showMedText = !this.showMedText;
        },
        toggleMText() {
            this.showMText = !this.showMText;
        },
        toggleSText() {
            this.showSText = !this.showSText;
        },
        toggleBText() {
            this.showBText = !this.showBText;
        },
            toggleKText() {
        this.showKText = !this.showKText;
        },
            toggleWText() {
        this.showWText = !this.showWText;
        },
            toggleOText() {
        this.showOText = !this.showOText;
        }
    },
}
</script>



<style lang="scss" scoped>

@import '../styles/style.scss';

    *
    {
        h1{
            color: #BD924A;
            cursor: pointer;
            text-align: center;
        }
        h2{
            color: #BD924A;
            cursor: pointer;
        }
        text-decoration: none;
        h3{
            color: #BD924A;
            text-align: center;
        }

    }
    #mainP
    {
        #mainhead
        {
            display: flex;
            flex-direction: column;
            #text-pic1{
                display: flex;
                justify-content: space-around;
                #th1{
                    text-align: center;
                }
                #text1{
                    display: flex;
                    text-align: justify;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                }
            }
            #text-pic2{
                display: flex;
                justify-content: space-around;
                #th2{
                    text-align: center;
                }
                #text2{
                    display: flex;
                    text-align: justify;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                }

            }
            #text-pic3{
                display: flex;
                justify-content: space-around;
                #th3{
                    text-align: center;
                }
                #text3{
                    display: flex;
                    text-align: justify;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                }
            }
            #text-pic4{
                display: flex;
                align-items: center;
                justify-content: space-around;
                #th4{
                    text-align: center;
                }
                #text4{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                    text-align: justify;
                }
            }
            #bigtext{
                #th5{
                    text-align: center;
                }
                #th6{
                    text-align: center;
                }
                display: flex;
                align-items: center;
                justify-content: space-around;
                text-align: justify;
            }
        }
        #przerywnik1{
            align-items: center;
            justify-content: space-around;
            display: flex;
        }
        #main-entr{
            display: flex;
            flex-direction: column;
            #ent1{
                display: flex;
                align-items: center;
                justify-content: center;
                #entt1{
                    h2{
                        text-align: center;
                    }
                    text-align: justify;
                }

            }
            #ent2{
                display: flex;
                align-items: center;
                justify-content: space-around;
                #eh2{
                    text-align: center;
                }
                #entt2{
                    flex-direction: column;
                    display: flex;
                    text-align: justify;
                    h2{
                        text-align: center;
                    }
                }
                #entp2{
                    display: flex;
                    align-items: center;
                }
            }
            #ent3{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                #eh3{
                    text-align: center;
                }
                #entt3{
                    text-align: justify;
                    h2{
                        text-align: center;
                    }
                }
            }
            #ent4{
                display: flex;
                text-align: center;
                flex-direction: column;
                #eh4{
                    text-align: center;
                }
                #entp4{
                    display: flex;
                    align-items: center;
                    justify-content:space-around;
                }
                #entt4{
                    text-align: justify;
                    h2{
                        text-align: center;
                    }
                }
            }
            #ent5{
                display: flex;
                align-items: center;
                justify-content: space-around;
                #eh5{
                    text-align: center;
                }
                #eh6{
                    text-align: center;
                }
                #entt5{
                    display: flex;
                    flex-direction: column;
                    text-align: justify;
                    h2{
                        text-align: center;
                    }
                }
            }
            #ent6{
                display: flex;
                text-align: center;
                align-items: center;
                justify-content: center;
            }
            #eprzerywnik1{
                display: flex;
                justify-content: space-around;
            }
            #eprzerywnik2{
                display: flex;
                justify-content: space-around;
            }
            #eprzerywnik3{
                display: flex;
                justify-content: space-around;
            }#eprzerywnik4{
                display: flex;
                justify-content: space-around;
            }
            #eprzerywnik5{
                display: flex;
                justify-content: space-around;
            }
            #eprzerywnik6{
                display: flex;
                justify-content: space-around;
            }
            #eprzerywnik7{
                display: flex;
                justify-content: space-around;
            }
        }
        #przerywnikend{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .flex-container {
        display: flex;
        align-items: center;
        #text1{
           width: 300px;
        }
        #text2{
            width: 300px;
        }
        #text3{
            width: 300px;
        }
        #text4{
            width: 300px;
        }
        #text5{
            width: 300px;
        }
        #text6{
            width: 300px;
        }
        #entt2{
            width: 400px;
        }

        #entt5{
            width: 50%;
        }
        #entt1{
            width: 55vw;
        }
        #entt3{
            width: 55vw;
        }
        #entt4{
            width: 55vw;
        }
        #stymt1{
            width: 55vw;
        }
        #mezot1{
            width: 55vw;
        }
        #bott1{
            width: 55vw;
        }
        #kwast1{
            width: 55vw;
        }
        #wolut1{
            width: 55vw;
        }
        #osoczet1{
            width: 55vw;
        }
        #mikrot1{
            width: 55vw;
        }
        #rett1{
            width: 55vw;
        }
        #kwt1{
            width: 55vw;
        }
        #rft1{
            width: 55vw;
        }
    }
    @media only screen and (max-width: 768px) {
        .flex-container {
            flex-direction: column;
            justify-content: space-between;

            #text1{
                width: 70vw;
            }
            #text2{
                width: 70vw;
            }
            #text3{
                width: 70vw;
            }
            #text4{
                width: 70vw;
            }
            #text5{
                width: 70vw;
            }
            #text6{
                width: 70vw;
            }
            #entt1{
                width: 70vw;
            }
            #entt2{
                width: 70vw;
            }
            #entt3{
                width: 70vw;
            }
            #entt4{
                width: 70vw;
            }
            #entt5{
                width: 70vw;
            }
            #stymt1{
                width: 70vw;
            }
            #mezot1{
                width: 70vw;
            }
            #bott1{
                width: 70vw;
            }
            #kwast1{
                width: 70vw;
            }
            #wolut1{
                width: 70vw;
            }
            #osoczet1{
                width: 70vw;
            }
            #mikrot1{
                width: 70vw;
            }
            #rett1{
                width: 70vw;
            }
            #kwt1{
                width: 70vw;
            }
            #rft1{
                width: 70vw;
            }
        }
    }
</style>