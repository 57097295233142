<template>
	<div id="main-promo">
		<img src="../assetsPng/promo1.png">
		<img src="../assetsPng/promo2.png">
		<img src="../assetsPng/promo3.png">
		<img src="../assetsPng/promo4.png">
		<img src="../assetsPng/promo5.png">
	</div>
</template>

<script>
export default {
	
};
</script>

<style lang="scss" scoped>

@import '../styles/style.scss';

    *
    {
        h1{
            color: #BD924A;
            cursor: pointer;
            text-align: center;
        }
        h2{
            color: #BD924A;
            cursor: pointer;
        }
        text-decoration: none;
        h3{
            color: #BD924A;
            text-align: center;
        }

    }
	#main-promo{
		display: flex;
		flex-direction: column;
		justify-content: center;
		
	}
</style>