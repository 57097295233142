import { createRouter, createWebHistory } from 'vue-router'
import mainPage from '../components/mainPage'
import contactPage from '../components/contactPage'
import pricingPage from '../components/pricingPage'
import galleryPage from '../components/galleryPage.vue'
import teamPage from '../components/teamPage.vue'
import estethicmedicinePage from '../components/estethicmedicinePage'
import cosmetologyPage from '../components/cosmetologyPage'
import drzwolPage from '../components/drzwolPage'
import drzylPage from '../components/drzylPage'
import laserotherapyPage from '../components/laserotherapyPage.vue'
import promotionsPage from '../components/promotionsPage.vue'
// import fotonaPage from '../components/fotonaPage.vue'
//import -> ścieżka
const routes = [
  {
    path: '/',
    name: 'mainPage',
    component: mainPage,
  },
  {
    path: '/kontakt',
    name: 'contactPage',
    component: contactPage,
  },
  {
    path: '/cennik',
    name: 'pricingPage',
    component: pricingPage,
  },
  {
    path: '/galeria',
    name: 'galleryPage',
    component: galleryPage,
  },
  {
    path: '/zespol',
    name: 'teamPage',
    component: teamPage,
  },
  {
    path: '/medycyna-estetyczna',
    name: 'estethicmedicinePage',
    component: estethicmedicinePage,
  },
  {
    path: '/kosmetologia',
    name: 'cosmetologyPage',
    component: cosmetologyPage,
  },
  {
    path: '/Dr_Zwolinska',
    name: 'drzwolPage',
    component: drzwolPage,
  },
  {
    path: '/Dr_Zylkowska',
    name: 'drzylPage',
    component: drzylPage,
  },
  {
    path: '/laseroterapia',
    name: 'laseroterapia',
    component: laserotherapyPage,
  },
  // {
  //   path: '/fotona',
  //   name: 'fotona',
  //   component: fotonaPage
  // },
  {
    path: '/aktualnosci',
    name: 'aktualnosci',
    component: promotionsPage,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition)
  {
    if (savedPosition)
    {
      return savedPosition
    }

    else
    {
      return { top: 0 }
    }
  },
})

export default router