<template>
    <h1>KOSMETOLOGIA</h1>
    <br>
    <div id="Kosmetologia">
        <div id="mezo-mikro" class="flex-container">
            <h2>MEZOTERAPIA MIKROIGŁOWA</h2>
            <div id="mikrot1">
                <h3>Odkryj sekret promiennej skóry z mezoterapią mikroigłową!</h3>
                <p>
                    W poszukiwaniu doskonałej, odmłodzonej i zdrowo wyglądającej skóry,
                    mezoterapia mikroigłowa wyróżnia się jako jedna z najbardziej
                    zaawansowanych i skutecznych metod pielęgnacyjnych dostępnych na
                    rynku. To innowacyjne rozwiązanie, które łączy w sobie precyzję
                    mikroigłowania z głęboką regeneracją, otwiera nowe możliwości w
                    dziedzinie kosmetologii estetycznej, dostarczając spektakularnych
                    efektów już po pierwszych zabiegach.
                </p>
                <h3>Czym jest mezoterapia mikroigłowa?</h3>
                <p>
                    Mezoterapia mikroigłowa to minimalnie inwazyjna procedura,
                    która wykorzystuje specjalistyczne urządzenie wyposażone w drobne
                    igły. Podczas zabiegu igły delikatnie penetrują skórę, tworząc
                    mikroskopijne kanały, które ułatwiają przenikanie aktywnych
                    składników odżywczych głęboko w tkankę skórną. Ta kontrolowana
                    "mikrouszkodzenie" skóry stymuluje naturalne procesy regeneracyjne,
                    w tym produkcję kolagenu i elastyny, co przekłada się na widoczną
                    poprawę stanu i wyglądu skóry.
                </p>
                <h3>Zalety mezoterapii mikroigłowej:</h3>
                <div id="mezo-mikroText">
                    <p>
                        •	<strong>Redukcja zmarszczek i drobnych linii</strong>: Stymulacja produkcji kolagenu sprawia, że skóra staje się bardziej jędrna, elastyczna i gładka.
                        <br>
                        •	<strong>Poprawa tekstury i kolorytu skóry</strong>: Zabieg wyrównuje koloryt, zmniejsza widoczność porów i rozjaśnia przebarwienia, przywracając skórze naturalny blask.
                        <br>
                        •	<strong>Leczenie blizn potrądzikowych</strong>: Mezoterapia mikroigłowa jest skuteczna w redukcji blizn i nierówności skórnych, dzięki wspieraniu procesów naprawczych.
                        <br>
                        •	<strong>Głębokie nawilżenie skóry</strong>: Dostarczanie składników nawilżających bezpośrednio do głębszych warstw skóry zapewnia długotrwałe i intensywne nawilżenie.
                        <br>
                        •	<strong>Bezpieczeństwo i minimalne ryzyko</strong>: Zabieg jest bezpieczny dla wszystkich typów skóry i nie wiąże się z długim okresem rekonwalescencji.
                    </p>
                </div>
                <h3>Dla kogo jest mezoterapia mikroigłowa?</h3>
                <p>
                    Mezoterapia mikroigłowa jest idealna dla osób poszukujących efektywnego rozwiązania na
                    problemy skórne takie jak starzenie się skóry, zmarszczki, utrata jędrności, blizny,
                    rozszerzone pory czy przebarwienia. Dzięki swojej wszechstronności, zabieg można dostosować
                    do indywidualnych potrzeb i celów każdego klienta.
                </p>
                <h3>Personalizowane podejście dla maksymalnych efektów</h3>
                <p>
                    Kluczem do sukcesu jest personalizacja zabiegu, która uwzględnia specyfikę skóry,
                    jej potrzeby oraz oczekiwania klienta. Dzięki możliwości precyzyjnego doboru składników
                    aktywnych, mezoterapia mikroigłowa oferuje nie tylko spektakularne efekty w zakresie poprawy
                    jakości skóry, ale również zapewnia głębokie odżywienie i regenerację.
                </p>
                <p>
                    Wybierając mezoterapię mikroigłową, decydujesz się na inwestycję w swoją skórę,
                    która przyniesie długotrwałe i widoczne efekty. Pozwól sobie na luksus, jakim jest
                    posiadanie zdrowej, promiennej i młodo wyglądającej skóry każdego dnia. Odkryj moc
                    mezoterapii mikroigłowej i ciesz się piękną skórą, o jakiej zawsze marzyłeś!
                </p>
            </div>
        </div>
        <div id="przerywnikkos1">
            <img src="../assetsPng/przerywnik3.png" width="200" height="40">
        </div>
        <div id="retinal-infusion" class="flex-container">
            <h2>RETINAL INFUSION PEEL</h2>
            <div id="rett1">
                <h3>Przełom w odmładzaniu skóry: Retinal Infusion Peel od Osmosis</h3>
                <p>
                    Odkryj sekret młodzieńczej skóry z Retinal Infusion Peel – innowacyjnym zabiegiem marki Osmosis, który stanowi przełom w dziedzinie kosmetologii estetycznej. Ta zaawansowana procedura jest kluczem do odmłodzonej, gładkiej i promiennej skóry, oferując natychmiastowe rezultaty bez długiego okresu rekonwalescencji. Zabieg ten jest doskonałym rozwiązaniem dla tych, którzy pragną efektywnie zająć się oznakami starzenia, nie poddając się inwazyjnym procedurom.
                </p>
                <h3>Co wyróżnia Retinal Infusion Peel?</h3>
                <p>
                    Retinal Infusion Peel to unikalna formuła zawierająca stabilizowaną formę witaminy A (retinaldehyd), która jest znana ze swoich silnych właściwości odmładzających i regenerujących. Zabieg działa głęboko, odbudowując struktury DNA komórek bez inicjowania stanu zapalnego, minimalizując ryzyko podrażnień.
                </p>
                <h3>Korzyści z zabiegu Retinal Infusion Peel:</h3>
                <div id="ret-text">
                    <p>
                        •	<strong>Redukcja zmarszczek i linii mimicznych</strong>: Dzięki pobudzeniu produkcji kolagenu, skóra staje się bardziej jędrna i elastyczna, co znacząco zmniejsza widoczność zmarszczek i drobnych linii.
                        <br>
                        •	<strong>Poprawa tekstury i kolorytu skóry</strong>: Retinal Infusion Peel wyrównuje koloryt skóry, zmniejsza widoczność porów i przywraca skórze zdrowy blask.
                        <br>
                        •	<strong>Redukcja przebarwień i plam słonecznych</strong>: Zabieg skutecznie rozjaśnia przebarwienia, dając efekt bardziej jednolitej i promiennej cery.
                        <br>
                        •	<strong>Regeneracja i głębokie odżywienie skóry</strong>: Dostarcza skórze niezbędnych składników odżywczych, przyspieszając jej regenerację i wzmacniając naturalną barierę ochronną.
                    </p>
                </div>
                <h3>Dla kogo jest Retinal Infusion Peel?</h3>
                <p>
                    Zabieg jest idealny dla osób poszukujących skutecznego, ale delikatnego rozwiązania w walce z oznakami starzenia, przebarwieniami oraz dla tych, którzy pragną poprawić ogólną kondycję i wygląd skóry. Dzięki swojej łagodnej formule, jest odpowiedni dla większości typów skóry, w tym również dla skóry wrażliwej.
                </p>
                <h3>Doświadcz różnicy z Osmosis</h3>
                <p>
                    Retinal Infusion Peel od Osmosis to nie tylko zabieg kosmetyczny, ale inwestycja w zdrowie i piękno Twojej skóry. Dzięki połączeniu nauki i natury, Osmosis oferuje produkty i zabiegi najwyższej jakości, które zapewniają realne i trwałe rezultaty. Nie czekaj na efekty – odkryj je już teraz dzięki Retinal Infusion Peel i pozwól swojej skórze cieszyć się młodzieńczym blaskiem każdego dnia!
                </p>
            </div>
        </div>
        <div id="przerywnikkos2">
            <img src="../assetsPng/przerywnik3.png" width="200" height="40">
        </div>
        <div id="kwas-kos" class="flex-container">
            <h2>KWASY I PEELINGI</h2>
            <div id="kwt1">
                <h3>Odkryj potęgę profesjonalnych kwasów i peelingów kosmetologicznych!</h3>
                <p>
                    W dzisiejszym świecie, gdzie nieskazitelna cera jest synonimem piękna i zdrowia, profesjonalne kwasy i peelingi kosmetologiczne stanowią klucz do osiągnięcia zamierzonych celów pielęgnacyjnych. Zaprojektowane z myślą o różnorodnych potrzebach skóry, te zaawansowane formuły to nie tylko trend w świecie beauty, ale przede wszystkim efektywna odpowiedź na problemy skórne, które dotykają osób w każdym wieku.
                </p>
                <h3>Zalety profesjonalnych kwasów i peelingów kosmetologicznych:</h3>
                <div id="kwk-text1">
                    <p>
                        •	<strong>Odmłodzenie skóry</strong>: Stymulują produkcję kolagenu i elastyny, zapewniając skórze jędrność, elastyczność i wyraźne zmniejszenie widoczności zmarszczek.
                        <br>
                        •	<strong>Rozświetlenie i wyrównanie kolorytu</strong>: Skutecznie rozjaśniają przebarwienia, wyrównują koloryt skóry, nadając jej zdrowy i promienny wygląd.
                        <br>
                        •	<strong>Głębokie oczyszczenie</strong>: Pomagają w walce z trądzikiem i przetłuszczaniem się skóry, regulując pracę gruczołów łojowych i minimalizując pory.
                        <br>
                        •	<strong>Regeneracja i odnowa</strong>: Przyspieszają naturalny proces odnowy komórkowej, ujawniając nową, zdrowszą i bardziej promienną skórę.
                        <br>
                        •	<strong>Wzmacnianie bariery ochronnej</strong>: Przywracają skórze odpowiedni poziom nawilżenia i ochronę przed negatywnym wpływem czynników zewnętrznych.
                    </p>
                </div>
                <h3>Sposoby zastosowania:</h3>
                <div id="kwk-text2">
                    <p>
                        •		<strong>Peelingi chemiczne</strong>: Zawierające takie składniki jak kwas glikolowy, salicylowy, mlekowy czy azelainowy, są aplikowane przez profesjonalistów, aby złuszczać martwy naskórek i pobudzać skórę do regeneracji.
                        <br>
                        •		<strong>Peelingi enzymatyczne</strong>: Wykorzystują działanie enzymów owocowych do delikatnego oczyszczania i odświeżania cery, idealne dla osób z wrażliwą skórą.
                        <br>
                        •		<strong>Peelingi mechaniczne</strong>: Zawierają drobinki ścierające, które fizycznie usuwają martwe komórki skóry, sprawiając, że staje się ona gładka i miękka w dotyku.
                    </p>
                </div>
                <h3>Indywidualne podejście dla maksymalnych efektów</h3>
                <p>
                    Kluczem do sukcesu jest indywidualne dobranie rodzaju peelingu do potrzeb i typu skóry. Profesjonalna konsultacja z kosmetologiem pozwoli na stworzenie spersonalizowanego planu zabiegów, które będą nie tylko skuteczne, ale przede wszystkim bezpieczne dla Twojej skóry.
                </p>
                <h3>Wzmocnij swoją pielęgnację domową</h3>
                <p>
                    Integracja profesjonalnych zabiegów z odpowiednio dobraną pielęgnacją domową gwarantuje długotrwałe i zadowalające efekty. Regularne stosowanie rekomendowanych przez specjalistów produktów pozwoli na utrzymanie skóry w doskonałej kondycji między zabiegami
                </p>
                <p>
                    Zainwestuj w swoją skórę z profesjonalnymi kwasami i peelingami kosmetologicznymi – bo piękna, zdrowa skóra to najlepsza inwestycja, którą możesz dla siebie zrobić!
                </p>
            </div>
        </div>
        <div id="przerywnikkos3">
            <img src="../assetsPng/przerywnik3.png" width="200" height="40">
        </div>
        <div id="radio-mikro">
            <h2>RADIOFREKWENCJA MIKROIGŁOWA</h2>
            <div id="rmt1">
                <p>
                    Zabiegi z radiofrekwencją to innowacyjna metoda poprawy wygładzenia skóry, redukcji cellulitu
                    oraz ujędrniania ciała bez konieczności stosowania chirurgicznych technik.
                    <br>
                    Dzięki wykorzystaniu
                    energii radiowej, ta nowoczesna technologia stymuluje produkcję kolagenu, co pomaga w
                    zwiększeniu elastyczności skóry oraz redukcji zmarszczek.
                    <br>
                    Zabiegi te są bezbolesne, nieinwazyjne
                    i nie wymagają długiej rekonwalescencji, co sprawia, że są idealnym rozwiązaniem dla osób
                    pragnących poprawić kondycję swojej skóry w szybki i skuteczny sposób.
                </p>
                <p>
                    Zabiegi z radiofrekwencją posiadają wiele zalet, w tym:
                    <br>
                    • <strong>Brak konieczności stosowania chirurgii</strong> - radiofrekwencja jest nieinwazyjną metodą poprawy stanu
                    skóry, co oznacza brak potrzeby przeprowadzania operacji.
                    <br>
                    • <strong>Skuteczne ujędrnianie skóry</strong> - dzięki stymulacji produkcji kolagenu i elastyny, radiofrekwencja
                    pomaga w zwiększeniu jędrności i elastyczności skóry.
                    <br>
                    • <strong>Redukcja zmarszczek i linii mimicznych</strong> - energia radiowa pomaga w redukcji widoczności
                    zmarszczek oraz poprawie ogólnego wyglądu skóry.
                    <br>
                    • <strong>Bezbolesność i brak długiej rekonwalescencji</strong> - zabiegi z radiofrekwencją są mało inwazyjne, nie
                    powodują bólu i nie wymagają długiego okresu rekonwalescencji, co pozwala na szybkie powrót do
                    codziennych aktywności.
                    <br>
                    • <strong>Poprawa wyglądu skóry</strong> - regularne zabiegi z radiofrekwencją mogą przynieść efekty w postaci
                    bardziej jędrnej, gładkiej i młodszej skóry.
                </p>
                <h3>
                    Odkryj nowoczesne metody zachowania młodego wyglądu dzięki zabiegom z radiofrekwencją już
                    dziś! Zapraszamy na zabiegi przez cały rok
                </h3>
            </div>
        </div>
        <div id="przerywnikkos4">
            <img src="../assetsPng/przerywnik3.png" width="200" height="40">
        </div>
        <div id="ultradzwieki">
            <h2>ULTRADŹWIĘKI NA CIAŁO</h2>
            <div id="ultrat1">
                <h3>
                    Odkryj tajemnicę gładkiej, jędrnej skóry dzięki innowacyjnym zabiegom na ciało z użyciem
                    ultradźwięków!
                </h3>
                <p>
                    Nasza nowoczesna technologia ultradźwiękowa to klucz do perfekcyjnej sylwetki i
                    pięknej skóry.
                    <br>
                    Dzięki precyzyjnemu działaniu fal ultradźwiękowych, nasze zabiegi zapewniają rewelacyjne efekty
                    bez konieczności inwazyjnych procedur chirurgicznych.
                    <br>
                    Usprawniają one procesy metaboliczne w skórze, stymulują kolagen oraz redukują cellulit i zbędne tkanki tłuszczowej.
                    <br>
                    Poczuj się pewnie i pięknie w swoim ciele dzięki zabiegom opartym na najnowszej technologii ultradźwiękowej.
                </p>
                <p>
                    Zabiegi z użyciem ultradźwięków celowanych na ciało posiadają wiele korzyści i zalet. Oto kilka z nich:
                    <br>
                    • <strong>Redukcja cellulitu</strong>: Ultradźwięki mogą pomóc w rozkładaniu nagromadzonych komórek
                    tłuszczowych, co przyczynia się do zmniejszenia widoczności cellulitu.
                    <br>
                    • <strong>Wzmocnienie skóry</strong>: Zabiegi ultradźwiękowe mogą stymulować produkcję kolagenu i
                    elastyny, co poprawia jędrność i elastyczność skóry.
                    <br>
                    • <strong>Modelowanie sylwetki</strong>: Dzięki precyzyjnemu działaniu ultradźwięków możliwe jest
                    kształtowanie i modelowanie ciała, redukcja obwodów oraz poprawa jego wyglądu.
                    <br>
                    • <strong>Bezinwazyjność</strong>: Zabiegi z ultradźwiękami są nieinwazyjne, co oznacza, że nie wymagają
                    chirurgicznych cięć ani długiego okresu rekonwalescencji.
                    <br>
                    • <strong>Brak okresu rekonwalescencji</strong>: Po zabiegu z użyciem ultradźwięków można wrócić do
                    codziennych aktywności natychmiast po jego zakończeniu.
                    <br>
                    • <strong>Bezpieczeństwo</strong>: Zabiegi z ultradźwiękami są bezpieczne i skuteczne, nie powodując przy
                    tym powikłań czy efektów ubocznych.
                </p>
                <p>
                    Dzięki tym zaletom, zabiegi z ultradźwiękami celowanymi na ciało są coraz bardziej popularne
                    wśród osób poszukujących skutecznych metod poprawy kondycji skóry i sylwetki.
                </p>
                <h3>
                    Skorzystaj z naszych profesjonalnych usług i osiągnij wymarzone rezultaty!
                    Zadbaj o siebie i swoje ciało już dziś!
                </h3>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    }
}
</script>

<style lang="scss" scoped>
@import '../styles/style.scss';
    *
    {
        h1{
            color: #BD924A;
            cursor: pointer;
            text-align: center;
        }
        h2{
            color: #BD924A;
            cursor: pointer;
        }
        text-decoration: none;
        h3{
            color: #BD924A;
            text-align: center;
        }

    }
    #Kosmetologia{
        display: flex;
        flex-direction: column;
        text-align: center;
        #mezo-mikro{
            display: flex;
            flex-direction: column;
            align-items: center;
            #mikrot1{
                text-align: justify;
            }
        }
        #retinal-infusion{
            display: flex;
            flex-direction: column;
            align-items: center;
            #rett1{
                text-align: justify;
            }
        }
        #kwas-kos{
            display: flex;
            flex-direction: column;
            align-items: center;
            #kwt1{
                text-align: justify;
            }
        }
        #rf{
            display: flex;
            flex-direction: column;
            align-items: center;
            #rft1{
                text-align: justify;
            }
        }
        #radio-mikro{
            display: flex;
            flex-direction: column;
            align-items: center;
            #rmt1{
                text-align: justify;
            }
        }
        #ultradzwieki{
            display: flex;
            flex-direction: column;
            align-items: center;
            #ultrat1{
                text-align: justify;
            }
        }
        #przerywnikkos0{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        #przerywnikkos1{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        #przerywnikkos2{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        #przerywnikkos3{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        #przerywnikkos4{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
</style>