<template>
    <div v-if="isMobile">
        <div id="mobilebaner">
            <div class="dropdown">
                <div>
                    <b-dropdown variant="transparent">
                        <template #button-content>
                            <span>MENU</span>
                        </template>
                        <b-dropdown-item @click="handleClick('o nas')" class="b-dropdown-item">O NAS</b-dropdown-item>
                        <b-dropdown-item @click="handleClick('zespol')" class="b-dropdown-item">ZESPÓŁ</b-dropdown-item>
                        <b-dropdown-item @click="handleClick('medycyna-estetyczna')" class="b-dropdown-item">MEDYCYNA ESTETYCZNA</b-dropdown-item>
                        <b-dropdown-item @click="handleClick('kosmetologia')" class="b-dropdown-item">KOSMETOLOGIA</b-dropdown-item>
                        <b-dropdown-item @click="handleClick('laseroterapia')" class="b-dropdown-item">LASEROTERAPIA</b-dropdown-item>
                        <!-- <b-dropdown-item @click="handleClick('fotona')" class="b-dropdown-item">FOTONA 4D</b-dropdown-item> -->
                        <b-dropdown-item @click="handleClick('cennik')" class="b-dropdown-item">CENNIK</b-dropdown-item>
                        <b-dropdown-item @click="handleClick('aktualnosci')" class="b-dropdown-item">AKTUALNOŚCI</b-dropdown-item>
                        <b-dropdown-item @click="handleClick('kontakt')" class="b-dropdown-item">KONTAKT</b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
            <div id="baner-moblogo" >
              <router-link to="/">
                <a>KLINIKA MEDYCYNY ESTETYCZNEJ</a>
              </router-link>
                <router-link to="/">
                  <img src="../assetsPng/logonew.png"/>
                </router-link>
            </div>
            <div id="pusty">
            </div>
        </div>
    </div>
    <div v-else>
        <div id="baner">
            <div id="baner-left">
                <router-link to="/galeria">GALERIA</router-link>
                <div id="dropdown1">
                    <b-dropdown variant="transparent">
                        <template #button-content>
                            <span>USŁUGI</span>
                        </template>
                        <b-dropdown-item @click="handleClick('medycyna-estetyczna')" class="b-dropdown-item">MEDYCYNA ESTETYCZNA</b-dropdown-item>
                        <b-dropdown-item @click="handleClick('kosmetologia')" class="b-dropdown-item">KOSMETOLOGIA</b-dropdown-item>
                        <b-dropdown-item @click="handleClick('laseroterapia')" class="b-dropdown-item">LASEROTERAPIA</b-dropdown-item>
                        <b-dropdown-item @click="handleClick('aktualnosci')" class="b-dropdown-item">AKTUALNOŚCI</b-dropdown-item>
                        <!-- <b-dropdown-item @click="handleClick('fotona')" class="b-dropdown-item">FOTONA 4D</b-dropdown-item> -->
                    </b-dropdown>
                </div>
                <router-link to="/zespol">ZESPÓŁ</router-link>
            </div>
            <div id="baner-logo" >
                <router-link to="/">
                <a>KLINIKA MEDYCYNY ESTETYCZNEJ</a>
                </router-link>
                <router-link to="/">
                <img src="../assetsPng/logonew.png"/>
                </router-link>
            </div>
            <div id="baner-right">
                <a @click.prevent="handleClick('o nas')">O NAS</a>
                <a id="link1" href="https://rejestracja.medfile.pl/register/index/?uuid=3aa71cad-f0cf-1e7f-13fd-3b4f5e9eeaa1">ZAPISY</a>
                <router-link to="/cennik">CENNIK</router-link>
                <router-link to="/kontakt">KONTAKT</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue-next';

export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      isOpen: false,
      options: [
        { value: "galeria", text: "GALERIA" },
        { value: "kosmetologia", text: "KOSMETOLOGIA" },
        { value: "medycyna-estetyczna", text: "MEDYCYNA ESTETYCZNA" },
        { value: "zespol", text: "ZESPÓŁ" },
        { value: "o nas", text: "O NAS" },
        { value: "cennik", text: "CENNIK" },
        { value: "kontakt", text: "KONTAKT" },
        { value: "fotona", text: "FOTONA" },
        { value: "aktualnosci", text: "AKTUALNOŚCI"}
      ],
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth <= 1000;
    },
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    toggleDropdown() {
      // Wywołaj zdarzenie kliknięcia dropdown
      this.$refs.dropdown1.toggle();
    },
    async handleClick(value) {
      if (value === "cennik") {
        this.$router.push("/cennik");
      } else if (value === "o nas") {
        this.$router.push('/').then(() => {
          setTimeout(() => {
            this.scrollToSection('mainhead');
          }, 80);
        });
      } else if (value === "kontakt") {
        this.$router.push("/kontakt");
      } else if (value === "klinika ja") {
        this.$router.push("/");
      } else if (value === "galeria") {
        this.$router.push("/galeria");
      } else if (value === "zespol") {
        this.$router.push("zespol");
      } else if (value === "kosmetologia") {
        this.$router.push("/kosmetologia");
      } else if (value === "medycyna-estetyczna") {
        this.$router.push("/medycyna-estetyczna");
      } else if (value === "laseroterapia") {
        this.$router.push("/laseroterapia");
      } else if (value === "fotona") {
        this.$router.push("/fotona");
      } else if (value === "aktualnosci"){
        this.$router.push("/aktualnosci");
      } else {
        this.selectedOption = this.options.find((option) => option.value === value).text;
      }
      this.isOpen = false;
    },
    scrollToSection(targetId) {
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        window.scrollTo({
          top: targetElement.offsetTop,
          behavior: 'smooth'
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/style.scss';
@import '@/assets/scss/_variables.scss';

* {
        background-color: #1f1c1c; /* Tło całej strony */
        color: #BD924A; /* Kolor tekstu */
        text-decoration: none;
        font-size: 16px;
    }

    .b-dropdown-item {
      background-color: transparent; /* Tło elementu transparentne */
      padding: 10px 20px; /* Odstępy */
      font-size: 16px; /* Rozmiar czcionki */
      cursor: pointer; /* Kursor w stylu wskaźnika */
    }
    .custom-transparent{
        font-size: 100px;
    }

    #baner
    {
        height: 120px;
        border-bottom: 1px solid #1f1c1c;

        display: flex;
        justify-content: space-between;

        #baner-left
        {
            width: 40%;
            height: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            cursor: pointer;
            #uslugi {
              position: absolute; /* Pozycjonowanie absolutne */
              left: 18.4%; /* Dostosuj tę wartość, aby przesunąć w lewo */
              top: 6.6%; /* Ustaw w pionie na środku */
              transform: translateY(-50%); /* Wyśrodkowanie w pionie */
              z-index: 1; /* Umożliwia wyświetlenie na wierzchu */
              cursor: pointer;
              font-size: 16px; /* Dostosuj rozmiar czcionki */
              color: #BD924A; /* Ustaw kolor czcionki */
          }
        }
        #baner-right
        {
            width: 40%;
            height: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            cursor: pointer;
        }
        #baner-logo
        {
          width: 20%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          text-align: center;
        }
    }
    @media only screen and (max-width: 1000px)
    {
        #mobilebaner {
            flex: 1;
            display: flex;
            height: 30%;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            #dropdown {
                width: 20%;
                display: flex;
            }
            #baner-moblogo {
                width: 60%;
                display: flex;
                flex-direction: column;
                text-align: center;
            }
            #pusty{
                width: 20%;
            }
        }
    }
    html {
      scroll-behavior: smooth;
    }
</style>